import React from 'react';
import Slider from 'react-slick';
import { Box, styled, Typography } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import A7 from '../../assets/img/cards/A7.jpg';
import A8 from '../../assets/img/cards/A8.jpg';
import B7 from '../../assets/img/cards/B7.jpg';
import B8 from '../../assets/img/cards/B8.jpg';



// Sample images, replace these with your own card image paths
const cardImages = [
  A7,
  A8,
  B7,
  B8
  // ...add paths for all 10 images
];

// Use styled API from MUI for custom styles
const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  margin: '0 auto', // Centers the image
}));

const CardCarousel = () => {
  // Settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 600, // theme.breakpoints.values.sm can be used if the theme is available
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 380, // theme.breakpoints.values.xs can be used if the theme is available
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 10 }}>
     
        <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mb: 4 }}>
         Sfoglia alcune delle 40 Cards della collezione
        </Typography>


      <Slider {...settings}>
        {cardImages.map((src, index) => (
          <div key={index}>
            <StyledImage src={src} alt={`Card ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </Box>
  );
};

export default CardCarousel;
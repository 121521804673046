import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, Arial',
  },
  palette: {
    primary: {
      main: '#30C5FF', // Replace with your desired color
    },
    grey:{
        main: '#888888',
    },
    light_grey:{
        main: '#dedede',
    }
  },
  // ...other overrides
});

export default theme;
import React from 'react';
import { Box, Grid, Typography, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const InfoSection = ({ imageUrl, title, description, buttonText, buttonurl }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
        // margin: theme.spacing(4),
        px: theme.spacing(20),
        p: 2,
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      }}
    >
      <Box sx={{ flexShrink: 0, minWidth: 250, padding: theme.spacing(2) }}>
        <Box
          component="img"
          src={imageUrl}
          alt="Description"
          sx={{ width: "100%", maxWidth: 500, height: "auto" }}
        />
      </Box>
      <Box sx={{ flex: 1, px: theme.spacing(6) }}>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>

        <Typography variant="h6" sx={{ py: 6 }}>
          {description.split(".").map(
            (sentence, index, array) =>
              sentence.trim() && (
                <React.Fragment key={index}>
                  {sentence.trim()}
                  {/* Add period back except for the last sentence */}
                  {index < array.length - 1 ? "." : ""}
                  <br />
                </React.Fragment>
              )
          )}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ color: "white" }}
          onClick={() => navigate(buttonurl)}
        >
          {buttonText}
        </Button>
      </Box>
    </Grid>
  );
};

export default InfoSection;
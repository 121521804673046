import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import Navbar from './Navbar';
import Footer from './Footer';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';

function PrivacyPolicy() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '50vh',
          }}
        >
          <Typography component="h2" variant="h2" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography component="p" variant="body1" align="center" sx={{ marginTop: 4 }}>
            Qui troverai le informazioni relative a come gestiamo i tuoi dati.
          </Typography>
          <Typography component="h5" variant="h5" align="center" sx={{ marginTop: 2 }}>
          Titolare del Trattamento
          </Typography>
          <Typography component="p" variant="body1" align="center" sx={{ marginTop: 2 }}>
            Il Titolare del trattamento dei dati personali raccolti su questo sito è Fluel, flue.it contattabile all'indirizzo email: info@fluel.it.
            </Typography>
          <Typography component="h5" variant="h5" align="center" sx={{ marginTop: 2 }}>
            Tipologia di Dati Raccolti
            </Typography>
          <Typography component="p" variant="body1" align="center" sx={{ marginTop: 2 }}>

            Il sito raccoglie i seguenti dati personali tramite il form di iscrizione e di richiesta di upgrade a pro:

            Nome
            Cognome
            Via (opzionale)
            Città
            CAP (opzionale)
            Provincia
            Indirizzo email
            Numero Ordine (opzionale)
            Questi dati sono forniti direttamente dagli utenti tramite i form presenti sul sito e sono necessari per l’erogazione del servizio.
            </Typography>

            <Typography component="h5" variant="h5" align="center" sx={{ marginTop: 2 }}>
                Modalità di Raccolta dei Dati
            </Typography>
          <Typography component="p" variant="body1" align="center" sx={{ marginTop: 2 }}>

I dati sono raccolti esclusivamente attraverso il form di iscrizione e di richiesta di upgrade a pro, con l’unico scopo di abilitare la versione completa del sito e per inviare informazioni commerciali su nuove versioni del prodotto. I dati non vengono ceduti a terzi e sono utilizzati per statistiche di accesso e di utilizzo della piattaforma, per la profilazione e per finalità di marketing. Non viene memorizzato l'indirizzo IP degli utenti.

            </Typography>

            <Typography component="h5" variant="h5" align="center" sx={{ marginTop: 2 }}>
Base Giuridica del Trattamento
            </Typography>

          <Typography component="p" variant="body1" align="center" sx={{ marginTop: 2 }}>

            Il trattamento dei dati è basato sul consenso espresso dell'utente al momento dell'iscrizione e dell'accettazione dei termini d'uso e della presente privacy policy. Il conferimento dei dati è necessario per l'utilizzo del servizio, che è fornito gratuitamente.
            </Typography>
            <Typography component="h5" variant="h5" align="center" sx={{ marginTop: 2 }}>

Destinatari dei Dati
            </Typography>
          <Typography component="p" variant="body1" align="center" sx={{ marginTop: 2 }}>

I dati raccolti non vengono condivisi con terze parti, ad eccezione di Google, tramite il servizio Firebase, per la gestione dei dati su server extra-UE, come descritto nella privacy policy di Firebase. L'accesso ai dati è riservato esclusivamente all'amministratore della piattaforma, che li utilizza per la gestione della piattaforma, delle autorizzazioni, delle statistiche e per la messaggistica interna tra l'admin e gli utenti.
            </Typography>

            <Typography component="h5" variant="h5" align="center" sx={{ marginTop: 2 }}>
Conservazione dei Dati
            </Typography>

          <Typography component="p" variant="body1" align="center" sx={{ marginTop: 2 }}>
I dati personali sono conservati per il tempo necessario a fornire il servizio richiesto dall'utente e a ottemperare agli obblighi legali. I criteri di conservazione sono determinati in base alle finalità per cui i dati sono raccolti e trattati.

Diritti degli Interessati

Gli utenti hanno il diritto di:

Accedere ai propri dati personali
Richiedere la rettifica dei propri dati
Richiedere la cancellazione dei propri dati
Limitare il trattamento dei dati personali
Esercitare il diritto alla portabilità dei dati
Opporsi al trattamento dei propri dati per motivi legittimi
Per esercitare questi diritti, gli utenti possono contattare il Titolare del trattamento all'indirizzo email: info@fluel.it.
            </Typography>

            <Typography component="h5" variant="h5" align="center" sx={{ marginTop: 2 }}>
Misure di Sicurezza
            </Typography>

          <Typography component="p" variant="body1" align="center" sx={{ marginTop: 2, marginBottom:5 }}>
Il sito utilizza le misure di sicurezza fornite da Firebase, che includono la crittografia dei dati in transito tramite HTTPS e l'isolamento logico dei dati dei clienti. Maggiori informazioni sulle misure di sicurezza adottate sono disponibili nella privacy policy di Firebase.

Eventuali modifiche alla presente privacy policy saranno comunicate agli utenti tramite la messaggistica interna del sito.
            <Typography component="h5" variant="h5" align="center" sx={{ marginTop: 2 }}>

Contatti
            </Typography>

Per qualsiasi domanda o richiesta riguardante la presente privacy policy, è possibile contattare il Titolare del trattamento all'indirizzo email: info@fluel.it.
          </Typography>


          <Typography component="h2" variant="h2" align="center"gutterBottom>
        Termini di Servizio
      </Typography>

      <Typography variant="h6" align="center" sx={{ marginTop: 2 }} gutterBottom>
        1. Descrizione del Servizio
      </Typography>
      <Typography variant="body1" align="center" sx={{ marginTop: 2 }} gutterBottom>
        AI Cards è il sito associato al libro <em>Sinergie Generative</em>, che spiega tecniche di brainstorming attraverso l'utilizzo di card tematiche fornite in formato digitale. Il servizio è gratuito e fornisce un set limitato di dieci card. Per poter visualizzare il set completo, è necessario acquistare il libro e fornire la ricevuta di acquisto su Amazon tramite il form per richiedere gratuitamente il passaggio alla versione pro. Le card sono fornite in un canvas digitale e possono essere posizionate liberamente insieme a testi e note. Ogni utente dispone di un canvas privato, non accessibile ad altri.
      </Typography>

      <Typography variant="h6"  align="center" sx={{ marginTop: 2 }} gutterBottom>
        2. Accesso e Registrazione
      </Typography>
      <Typography variant="body1" align="center" sx={{ marginTop: 2 }} gutterBottom>
        Il sito è accessibile da chiunque, non avendo contenuti vietati. Tuttavia, è destinato a un pubblico di età maggiore di 16 anni ed è rivolto principalmente al mercato italiano. Il servizio è per un uso personale e non commerciale. Ogni utente è responsabile dei propri contenuti, e Fluel è sollevato da ogni responsabilità riguardo ai contenuti caricati, distribuiti o esportati dalla piattaforma, nonché sui risultati derivanti dall'applicazione del metodo descritto.
      </Typography>

      <Typography variant="h6" align="center" sx={{ marginTop: 2 }} gutterBottom>
        3. Limitazione di Responsabilità
      </Typography>
      <Typography variant="body1" align="center" sx={{ marginTop: 2 }}  gutterBottom>
        Fluel declina ogni responsabilità per malfunzionamenti, perdita di dati, accesso non autorizzato o qualsiasi altra situazione imprevista. Il servizio viene fornito "così com'è", senza garanzie implicite o esplicite. Fluel si riserva il diritto di modificare o interrompere il servizio in qualsiasi momento a suo insindacabile giudizio, senza alcuna responsabilità per eventuale perdita di dati o malfunzionamenti.
      </Typography>

      <Typography variant="h6" align="center" sx={{ marginTop: 2 }} gutterBottom>
        4. Sospensione e Cessazione dell'Account
      </Typography>
      <Typography variant="body1" align="center" sx={{ marginTop: 2 }} gutterBottom>
        Fluel può sospendere o chiudere un account utente a suo insindacabile giudizio per violazione dei termini di servizio, uso improprio del servizio, inattività, rivendita non autorizzata o violazione della licenza. Gli utenti possono richiedere la cancellazione del proprio account inviando una richiesta a <a href="mailto:info@fluel.it">info@fluel.it</a>.
      </Typography>

      <Typography variant="h6" align="center" sx={{ marginTop: 2 }} gutterBottom>
        5. Legge Applicabile e Foro Competente
      </Typography>
      <Typography variant="body1" align="center" sx={{ marginTop: 2 }} gutterBottom>
        I presenti termini di servizio sono regolati dalla legge italiana. Per qualsiasi controversia è competente il foro di Bologna.
      </Typography>

      <Typography variant="h6" align="center" sx={{ marginTop: 2 }} gutterBottom>
        6. Accettazione dei Termini
      </Typography>
      <Typography component="p" variant="body1" align="center" sx={{ marginTop: 2, marginBottom:5 }}>
        Mediante la registrazione e l'uso del servizio, nonché l'accettazione esplicita in fase di registrazione, l'utente accetta integralmente i presenti termini di servizio.
      </Typography>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default PrivacyPolicy;

import React, { useState } from 'react';
import { auth } from '../firebase-config';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Navbar from './Navbar';
import Footer from './Footer';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import { TextField, Button, Box, Typography, Link as MuiLink } from '@mui/material';
import { Container } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc } from "firebase/firestore";


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const db = getFirestore();

  const login = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        setError('L\'email non è ancora stata verificata. Controlla la tua casella di posta.');
        return;
      }

      // Log the login event in Firestore
      const loginTimestamp = new Date().toISOString();
      await setDoc(doc(db, `userLogins/${user.uid}/logins/${loginTimestamp}`), {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || 'N/A',
        timestamp: loginTimestamp,
      });

      console.log('Login effettuato con successo:', user); // Remove this log in production

      // Save the login timestamp in local storage
      localStorage.setItem('lastLoginTimestamp', loginTimestamp);

      // Redirect to dashboard
      navigate('/dashboard'); 

    } catch (error) {
      console.error('Errore durante il login:', error.message);
      setError('Errore durante il login: ' + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    setError('');
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Log the login event in Firestore
      const loginTimestamp = new Date().toISOString();
      await setDoc(doc(db, `userLogins/${user.uid}/logins/${loginTimestamp}`), {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || 'N/A',
        timestamp: loginTimestamp,
      });

      console.log('Login effettuato con successo:', user); // Remove this log in production

      // Save the login timestamp in local storage
      localStorage.setItem('lastLoginTimestamp', loginTimestamp);

      // Redirect to dashboard
      navigate('/dashboard');

    } catch (error) {
      console.error('Errore durante il login con Google:', error.message);
      setError('Errore durante il login con Google: ' + error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: "50vh"
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter your email here"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Enter your password here"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={login}
            >
              Log in
            </Button>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              sx={{ mt: 1, mb: 2 }}
              onClick={handleGoogleLogin}
            >
              Log in with Google
            </Button>
            <MuiLink component={Link} to="/signup" variant="body2" display="block" sx={{ mt: 2 }}>
              Registra Nuovo Utente
            </MuiLink>
          </Box>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default Login;

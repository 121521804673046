import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as LogoSvg } from '../assets/img/Site_Title_ITA.svg';
import { useAuth } from '../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase-config';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, IconButton, Modal, Box, Typography, List, ListItem, ListItemText, Button as MuiButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import { getFirestore, collection, query, where, getDocs,getDoc, updateDoc, doc, arrayUnion } from 'firebase/firestore';

const Navbar = () => {
  const theme = useTheme();
  const { currentUser, role } = useAuth();
  const navigate = useNavigate();
  const [unreadCount, setUnreadCount] = useState(0);
  const [messages, setMessages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const db = getFirestore();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Errore durante il logout:', error);
    }
  };

  // Fetch unread messages count and messages list
  useEffect(() => {
    if (currentUser) {
      const fetchMessages = async () => {
        const q = query(
          collection(db, 'messages'),
          where('recipientIds', 'array-contains', currentUser.uid)
        );

        const allUsersQuery = query(
          collection(db, 'messages'),
          where('recipientIds', '==', [])
        );

        const personalMessagesSnapshot = await getDocs(q);
        const allUsersMessagesSnapshot = await getDocs(allUsersQuery);

        const unreadMessages = [];

        personalMessagesSnapshot.forEach((doc) => {
          if (!doc.data().readBy?.includes(currentUser.uid)) {
            unreadMessages.push({ id: doc.id, ...doc.data() });
          }
        });

        allUsersMessagesSnapshot.forEach((doc) => {
          if (!doc.data().readBy?.includes(currentUser.uid)) {
            unreadMessages.push({ id: doc.id, ...doc.data() });
          }
        });

        setUnreadCount(unreadMessages.length);
        setMessages(unreadMessages);
      };

      fetchMessages();
    }
  }, [currentUser, db]);

  const handleMarkAsRead = async (messageId) => {
    const messageRef = doc(db, 'messages', messageId);

    try {
        // Use arrayUnion to add the current user's UID to the readBy array
        
        await updateDoc(messageRef, {
            readBy: arrayUnion(currentUser.uid),
        });

        // Refresh the messages list
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));
        setUnreadCount((prevCount) => prevCount - 1);
    } catch (error) {
        console.error('Error marking message as read:', error);
    }
};


  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  // Define role-based colors
  const getRoleColor = (role) => {
    switch (role) {
      case 'admin':
        return '#d32f2f'; // Red for Admin
      case 'pro':
        return '#1976d2'; // Blue for Pro
      case 'free':
      default:
        return '#4caf50'; // Green for Free
    }
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <LogoSvg
          style={{
            width: "auto",
            height: "30px",
            marginBottom: { xs: theme.spacing(2), sm: 0 },
            marginTop: 7,
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {currentUser && role && (
            <Badge
              badgeContent={role}
              color="primary"
              sx={{
                mr: 2, // Adjust margin to space it properly
                '& .MuiBadge-badge': {
                  backgroundColor: getRoleColor(role), // background color based on role
                  color: 'white', // Set the text color to white
                },
              }}
            />
          )}

          {currentUser && (
            <>
              <IconButton
                sx={{ my: 1, mx: 1.5, color: "inherit" }}
                aria-label="inbound messages"
                onClick={handleOpenModal}
              >
                <Badge badgeContent={unreadCount} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>

              <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography id="modal-title" variant="h6" component="h2">
                    Unread Messages
                  </Typography>
                  <List>
                    {messages.map((message) => (
                      <ListItem key={message.id} alignItems="flex-start">
                        <ListItemText
                          primary={message.subject}
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {message.body}
                              </Typography>
                              {" — " + new Date(message.timestamp.seconds * 1000).toLocaleString()}
                            </>
                          }
                        />
                        <MuiButton
                          size="small"
                          color="primary"
                          onClick={() => handleMarkAsRead(message.id)}
                        >
                          Mark as Read
                        </MuiButton>
                      </ListItem>
                    ))}
                  </List>
                  <MuiButton onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained">Close</MuiButton>
                </Box>
              </Modal>
            </>
          )}

          <Button
            component={Link}
            to="/"
            sx={{ my: 1, mx: 1.5, color: "inherit" }}
          >
            Home
          </Button>

          {currentUser ? (
            <div>
              <Button
                component={Link}
                to="/dashboard"
                sx={{ my: 1, mx: 1.5, color: "inherit" }}
              >
                Dashboard
              </Button>

              {/* Admin section button, visible only to admin users */}
              {role === 'Admin' && (
                <Button
                  component={Link}
                  to="/admindashboard"
                  sx={{ my: 1, mx: 1.5, color: "inherit" }}
                >
                  Admin
                </Button>
              )}
              {/* Admin section button, visible only to admin users */}
              {role === 'Admin' && (
                <Button
                  component={Link}
                  to="/adminmessages"
                  sx={{ my: 1, mx: 1.5, color: "inherit" }}
                >
                  <MailIcon />
                </Button>
              )}

              <span>{currentUser.email}</span>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          ) : (
            <Button
              component={Link}
              to="/login"
              variant="contained"
              sx={{ my: 1, mx: 1.5, color: "white" }}
            >
              Login
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

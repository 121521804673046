import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, Button } from '@mui/material';
import { getFirestore, collection, getDocs, query, where, updateDoc, doc,Timestamp,collectionGroup  } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { Line } from 'react-chartjs-2';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    BarController,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { getAuth, listUsers } from 'firebase/auth';
import { subDays, format } from 'date-fns';
import "../css/admindashboard.css"

// Register the necessary components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    BarController,
    Title,
    Tooltip,
    Legend
  );

const AdminDashboard = () => {
  const { currentUser, role } = useAuth();
  const [userCount, setUserCount] = useState(0);
  const [subscriptionsData, setSubscriptionsData] = useState({ labels: [], datasets: [] });
  const [loginData, setLoginData] = useState({ labels: [], datasets: [] });
  const [userRequests, setUserRequests] = useState([]);

  const db = getFirestore();

  useEffect(() => {
    const fetchUserData = async () => {
      // Fetch total users
      const usersSnapshot = await getDocs(collection(db, 'users'));
      setUserCount(usersSnapshot.size);

      // Fetch subscription data
      const subscriptionData = await getSubscriptionData();
      setSubscriptionsData(subscriptionData);

      // Fetch login data
      const loginData = await getLoginData();
      setLoginData(loginData);

      // Fetch users with pro requests
      const requests = await getUserRequests();
      setUserRequests(requests);
    };

    fetchUserData();
  }, [db]);

  const getSubscriptionData = async () => {
    const db = getFirestore();
  
    // Define the last 30 days range
    const today = new Date();
    const thirtyDaysAgo = subDays(today, 30);
  
    // Query Firestore for users created in the last 30 days
    const usersQuery = query(
      collection(db, 'users'),
      where('createdAt', '>=', Timestamp.fromDate(thirtyDaysAgo))
    );
  
    const usersSnapshot = await getDocs(usersQuery);
    const subscriptionCounts = {};
  
    // Process the user data
    usersSnapshot.forEach((doc) => {
      const user = doc.data();
      const creationDate = user.createdAt.toDate();
      const formattedDate = format(creationDate, 'yyyy-MM-dd');
  
      if (!subscriptionCounts[formattedDate]) {
        subscriptionCounts[formattedDate] = 0;
      }
      subscriptionCounts[formattedDate] += 1;
    });
  
    // Generate the last 30 days of labels
    const labels = Array.from({ length: 30 }, (_, i) => {
      const date = subDays(today, i);
      return format(date, 'yyyy-MM-dd');
    }).reverse();
  
    // Generate the data for the last 30 days
    const data = labels.map((label) => subscriptionCounts[label] || 0);
  
    return {
      labels: labels, // Dates for the last 30 days
      datasets: [
        {
          label: 'Subscriptions',
          data: data, // Subscription counts per day
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
        },
      ],
    };
  };

  const getLoginData = async () => {
    const db = getFirestore();
    const today = new Date();
    const thirtyDaysAgo = subDays(today, 30);
  
    // Format the thirtyDaysAgo date to match the string format in Firestore
    const formattedThirtyDaysAgo = format(thirtyDaysAgo, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSX'); 
  
    const loginCounts = {};
    const uniqueUserCounts = {};
  
    // Fetch all users
    const usersSnapshot = await getDocs(collection(db, 'users'));
  
    // Iterate over each user's logins subcollection
    for (const userDoc of usersSnapshot.docs) {
      const userId = userDoc.id;
      const userLoginsRef = collection(db, `userLogins/${userId}/logins`);
  
      const loginsQuery = query(
        userLoginsRef,
        where('timestamp', '>=', formattedThirtyDaysAgo) // Compare string dates
      );
  
      const loginsSnapshot = await getDocs(loginsQuery);
  
      loginsSnapshot.forEach((doc) => {
        const login = doc.data();
        
        // Convert the timestamp string to a Date object
        const loginDate = new Date(login.timestamp);
        if (isNaN(loginDate)) {
          console.error(`Invalid date string: ${login.timestamp}`);
          return; // Skip invalid dates
        }
  
        const formattedDate = format(loginDate, 'yyyy-MM-dd');
  
        // Track total logins
        if (!loginCounts[formattedDate]) {
          loginCounts[formattedDate] = 0;
        }
        loginCounts[formattedDate] += 1;
  
        // Track unique users
        if (!uniqueUserCounts[formattedDate]) {
          uniqueUserCounts[formattedDate] = new Set();
        }
        uniqueUserCounts[formattedDate].add(userId); // Add userId to set
      });
    }
  
    // Convert unique user sets to counts
    for (const date in uniqueUserCounts) {
      uniqueUserCounts[date] = uniqueUserCounts[date].size;
    }
  
    // Generate the last 30 days of labels
    const labels = Array.from({ length: 30 }, (_, i) => {
      const date = subDays(today, i);
      return format(date, 'yyyy-MM-dd');
    }).reverse();
  
    // Generate the data for the last 30 days
    const totalLoginsData = labels.map((label) => loginCounts[label] || 0);
    const uniqueUsersData = labels.map((label) => uniqueUserCounts[label] || 0);
  
    return {
      labels: labels, // Dates for the last 30 days
      datasets: [
        {
          label: 'Total Logins',
          data: totalLoginsData, // Total login counts per day
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          yAxisID: 'y',
        },
        {
          label: 'Unique Users',
          data: uniqueUsersData, // Unique user counts per day
          type: 'bar', // Use a bar chart for unique users
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y1',
        },
      ],
    };
  };
  

  const options = {
    scales: {
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Total Logins',
        },
      },
      y1: {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Unique Users',
        },
        grid: {
          drawOnChartArea: false, // Keep grid lines separate
        },
      },
    },
  };

  const getUserRequests = async () => {
    // Fetch user requests for becoming pro
    const requestsQuery = query(collection(db, 'submissions'), where('pending', '==', true));
    const requestsSnapshot = await getDocs(requestsQuery);
    return requestsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  const handleApproveProRequest = async (userId, requestId) => {
    // Update user role to pro and mark request as completed
    const userRef = doc(db, 'users', userId);
    const requestRef = doc(db, 'submissions', requestId);

    if (role != 'Admin' ){
        await updateDoc(userRef, { role: 'Pro' });
    }
    await updateDoc(requestRef, { pending: false });
    
    // Optionally, refresh the request list
    const requests = await getUserRequests();
    setUserRequests(requests);
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  };
  
  const cardStyle = {
    flex: 1,  // Make the cards flex to the same height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 10px',
  };

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
    <Box sx={{ p: 3 }} style={containerStyle}>
      <Grid container spacing={3}>
        {/* Card 1: Total Users and Subscription Data */}
        <Grid item xs={12} md={6} >
          <Card style={cardStyle}>
            <CardContent>
              <Typography variant="h5">Total Users: {userCount}</Typography>
              <Typography variant="h6">Subscriptions in the Last Month</Typography>
              <Line data={subscriptionsData} />
            </CardContent>
          </Card>
        </Grid>

        {/* Card 2: Login Data */}
        <Grid item xs={12} md={6}>
          <Card style={cardStyle}>
            <CardContent>
              <Typography variant="h6">Logins in the Last Month (Non-Admin)</Typography>
              <Line data={loginData} options={options} />
            </CardContent>
          </Card>
        </Grid>

        {/* Card 3: Pro Requests */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5">Pro Requests</Typography>
              {userRequests.length > 0 ? (
                userRequests.map((request) => (
                  <Box key={request.id} sx={{ mb: 2 }}>
                    <Typography variant="body1">
                      {request.name} ({request.email})
                    </Typography>
                    <Button 
                      variant="outlined" 
                      size="small" 
                      sx={{ mr: 1 }}  // Add margin-right of 1 unit between buttons
                      onClick={() => window.open(request.imageUrl, '_blank')}
                    >
                      View Image
                    </Button>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      size="small" 
                      sx={{ ml: 1 }}  // Add margin-left of 1 unit between buttons
                      onClick={() => handleApproveProRequest(request.userId, request.id)}
                    >
                      Approve
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography>No pending pro requests</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
    </ThemeProvider>
  );
};

export default AdminDashboard;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase-config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null); // State to store the user's role

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async  (user) => {
      setCurrentUser(user);
      
      if (user) {

        // Fetch user role from Firestore
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, 'users', user.uid));

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setRole(userData.role); // Store the user's role
        } else {
          console.error('User document not found');
          setRole(null); // Set to null or default role
        }
      

        
        setLoading(false);
        startInactivityTimer(user);
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);


  const startInactivityTimer = (user) => {
    const checkInactivity = () => {
      const lastSignInTime = new Date(user.metadata.lastSignInTime).getTime();
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - lastSignInTime;

      console.log('Current Time:', new Date(currentTime).toISOString());
      console.log('Last Sign-In Time:', new Date(lastSignInTime).toISOString());
      console.log('Time Difference (ms):', timeDiff);

      // Check if the time difference is greater than or equal to 1 hour (3600000 milliseconds)
      if (timeDiff >= 3600000) {
        console.log('User has been inactive for more than 1 hour. Logging out.');
        alert('You have been logged out due to inactivity.');
        signOut(auth);
      } else {
        console.log('User is still active.');
      }
    };

    // Check inactivity every minute (60000 milliseconds)
    const intervalId = setInterval(checkInactivity, 3600000);

    console.log('Inactivity timer started.');

    // Cleanup the interval on unmount or logout
    return () => {
      clearInterval(intervalId);
      console.log('Inactivity timer stopped.');
    };
  };

  const value = {
    currentUser,
    role, // Expose the role in the context value
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React, { useState, useEffect } from 'react';

const LoadSessionModal = ({ isOpen, onClose, onLoad, existingSessions }) => {
  const [selectedSession, setSelectedSession] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setSelectedSession('');
    }
  }, [isOpen]);

  const handleLoad = () => {
    if (selectedSession) {
      onLoad(selectedSession); // Load the session
      onClose(); // Close the modal
    } else {
      alert('Please select a session to load.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h5>Select a session to load</h5>
        <div className="form-group">
          <label htmlFor="sessionDropdown">Saved Sessions:</label>
          <select
            id="sessionDropdown"
            className="form-control"
            value={selectedSession}
            onChange={(e) => setSelectedSession(e.target.value)}
          >
            <option value="" disabled>Select a session</option>
            {existingSessions.map((session) => (
              <option key={session.id} value={session.id}>
                {session.name} - {new Date(session.timestamp).toLocaleString()}
              </option>
            ))}
          </select>
        </div>
        <button className="modal-button confirm-button" onClick={handleLoad}>Open</button>
        <button className="modal-button cancel-button" onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default LoadSessionModal;

import React, { useState } from 'react';
import { auth } from '../firebase-config';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendEmailVerification } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import Navbar from './Navbar';
import Footer from './Footer';
import { ThemeProvider, Button, Grid, TextField, Link as MuiLink, Typography, Checkbox, FormControlLabel } from '@mui/material';
import theme from '../theme';
import { Link, useNavigate } from 'react-router-dom';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [acceptPrivacy, setAcceptPrivacy] = useState(false); // State for checkbox
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Function to validate the password
  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /\d/.test(password);

    return hasUpperCase && hasSpecialChar && hasNumber;
  };

  const signup = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must contain at least one uppercase letter, one special character, and one number.');
      return;
    }

    try {
      setError('');
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);

      // Initialize Firestore and assign the 'Free' role to the new user
      const db = getFirestore();
      await setDoc(doc(db, `users/${user.uid}`), {
        role: 'Free',
        email: user.email,
        displayName: user.displayName || 'N/A',
        createdAt: new Date(),
        privacy: 'accepted', // Save the privacy acceptance
      });

      setSuccessMessage('Registration successful! Please verify your email. Now go to Home.');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('This email address is already in use. Please try logging in or use a different email.');
      } else {
        console.error('Error during registration:', error.message);
        setError(error.message);
      }
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Initialize Firestore and assign the 'Free' role to the new user (if not already in database)
      const db = getFirestore();
      const userDocRef = doc(db, `users/${user.uid}`);
      await setDoc(userDocRef, {
        role: 'Free',
        email: user.email,
        displayName: user.displayName || 'N/A',
        createdAt: new Date(),
        privacy: 'accepted', // Save the privacy acceptance
      }, { merge: true });

      setSuccessMessage('Registration successful with Google! You are now logged in.');
      navigate('/');
    } catch (error) {
      console.error('Error during Google Sign-In:', error.message);
      setError(error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12} sm={6}>
          <h2>Signup</h2>
          {error && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          {successMessage ? (
            <Typography color="primary" variant="body1">
              {successMessage} <MuiLink component={Link} to="/">Home</MuiLink>
            </Typography>
          ) : (
            <>
              <TextField
                label="Enter your email here"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Enter your password here"
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Confirm your password"
                variant="outlined"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                fullWidth
                margin="normal"
              />

              {/* Privacy Policy Checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptPrivacy}
                    onChange={(e) => setAcceptPrivacy(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    By signing up, you accept the{' '}
                    <MuiLink component={Link} to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </MuiLink>{' '}
                    and{' '}
                    <MuiLink component={Link} to="/terms-of-use" target="_blank" rel="noopener noreferrer">
                      Terms of Use
                    </MuiLink>{' '}
                    and authorize AIcards to send commercial information.
                  </Typography>
                }
                sx={{ mt: 2 }}
              />

              {/* Disable buttons if checkbox is not checked */}
              <Button
                onClick={signup}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={!acceptPrivacy}
              >
                Sign Up
              </Button>
              <Button
                onClick={signInWithGoogle}
                variant="outlined"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={!acceptPrivacy}
              >
                Sign Up with Google
              </Button>
              <MuiLink component={Link} to="/login" variant="body2" display="block" sx={{ mt: 2 }}>
                Already registered? Log in
              </MuiLink>
            </>
          )}
        </Grid>
      </Grid>
      <Footer />
    </ThemeProvider>
  );
}

export default Signup;

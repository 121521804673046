import React, { useCallback } from 'react';
import { Handle } from 'react-flow-renderer';

const CustomCardNode = ({ data, id }) => {
  // Double-click handler
  const handleDoubleClick = useCallback(() => {
    console.log('double click hit')
    if (data.onCardNodeUpdate) {
      data.onCardNodeUpdate(id, { ...data, isFlipped: !data.isFlipped });
    }
  }, [data, id]);

  return (
    <div
      style={{ textAlign: 'center', padding: '0', margin: '0', display: 'block', width: '200px' }}
      onDoubleClick={handleDoubleClick} // Ensure this is correctly set
    >
      <img
        src={data.isFlipped ? data.backImage : data.image}
        alt={data.label}
        style={{
          width: '100%',
          height: 'auto',
          transition: 'transform 0.6s',
          transform: data.isFlipped ? 'rotateY(360deg)' : 'rotateY(0deg)',
          backfaceVisibility: 'hidden',
        }}
      />
      <Handle
        id="a"
        type="source"
        position="top"
        style={{ background: '#afc2ae', left: '55%', width: '5px', height: '5px' }}
      />
      <Handle
        id="b"
        type="target"
        position="top"
        style={{ background: '#d3aaaa', left: '45%', width: '5px', height: '5px' }}
      />
      <Handle
        id="c"
        type="source"
        position="bottom"
        style={{ background: '#afc2ae', left: '55%', width: '5px', height: '5px' }}
      />
      <Handle
        id="d"
        type="target"
        position="bottom"
        style={{ background: '#d3aaaa', left: '45%', width: '5px', height: '5px' }}
      />
      <Handle
        id="e"
        type="source"
        position="left"
        style={{ background: '#afc2ae', top: '45%', width: '5px', height: '5px' }}
      />
      <Handle
        id="f"
        type="target"
        position="left"
        style={{ background: '#d3aaaa', top: '55%', width: '5px', height: '5px' }}
      />
      <Handle
        id="g"
        type="source"
        position="right"
        style={{ background: '#afc2ae', top: '45%', width: '5px', height: '5px' }}
      />
      <Handle
        id="h"
        type="target"
        position="right"
        style={{ background: '#d3aaaa', top: '55%', width: '5px', height: '5px' }}
      />
    </div>
  );
};

export default CustomCardNode;

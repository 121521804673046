import React from 'react'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
// import '../css/clientsection.css'; // Assicurati che il file CSS esista e sia collegato correttamente
import icon1 from '../../assets/img/1.png';
import icon2 from '../../assets/img/2.png';
import icon3 from '../../assets/img/3.png';
import icon4 from '../../assets/img/4.png';
import icon5 from '../../assets/img/5.png';
import icon6 from '../../assets/img/6.png';

const benefits = [
  {
    icon: icon1,
    title: 'Boosting di Creatività',
    description: 'Usa il nostro metodo per soluzioni nuove e originali che utilizzano l\'AI'
  },
  {
    icon: icon2,
    title: 'Un Brainstorming Innovativo',
    description: 'Ingaggia il tuo team attraverso una esperienza dinamica e coinvolgente per sfidare creatività e innovazione'
  },
  {
    icon: icon3,
    title: 'Genera Nuovi Prodotti',
    description: 'Hai il potere di generare con il tuo team nuovi prodotti e servizi potenziati dall\'AI'
  },
  {
    icon: icon4,
    title: 'Metodo Innovativo',
    description: 'Scopri il nuovo framework unico che fonde Design e AI. Non solo Product e Service Design ma una fusione di metodologie e orientamenti tecnologici di ultima generazione. '
  },
  {
    icon: icon5,
    title: 'Strumento Professionale',
    description: 'Che tu sia un leader, un creativo o un imprenditore, questo è il trampolino per l\'innovazione. Supera i limiti dei tecnicismi della GenAI e focalizzati sui plus del prodotto'
  },
  {
    icon: icon6,
    title: 'Genera Mappe del Futuro',
    description: 'Utilizza il nostro mazzo di carte per navigare attraverso possibili alternative future con la GenAI, visualizza nuovi percorsi e nuove sinergie implementative'
  },
];

const BenefitsSection = () => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={{ flexGrow: 1, px: isSmUp ? 10 : 3, py: 5, backgroundColor: theme.palette.background.paper }}>
  <Grid container spacing={2} alignItems="center" justifyContent="center">
    {benefits.map((benefit, index) => (
      <Grid item xs={12} sm={6} md={4} key={index} sx={{ textAlign: 'center' }}>
        <Box component="img" src={benefit.icon} alt={benefit.title} sx={{ height: 'auto', width: 80, mx: 'auto', display: 'block' }} />
        <Typography variant="h6" component="h3" sx={{ mt: 2, mb: 1 }}>
          {benefit.title}
        </Typography>
        <Typography variant="body1">
          {benefit.description}
        </Typography>
      </Grid>
    ))}
  </Grid>
</Box>
  );
};

export default BenefitsSection;
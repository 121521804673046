import React from 'react';
import { Box, Grid, Typography, Link, Paper, Stack, useTheme } from '@mui/material';
import payments from '../assets/img/payments.png'; 

const Footer = () => {
  const theme = useTheme();
  return (
    <Paper sx={{ width: '100vw', maxWidth: '100%', bgcolor: 'black', color: 'white' }} elevation={0}>
      <Grid container spacing={2} sx={{ p: { xs: theme.spacing(2), md: theme.spacing(6) } }}>
        <Grid item xs={12} sm={3}>
          {/* <Typography variant="subtitle1" gutterBottom>
            Spediamo in tutta Italia
          </Typography>
          <Typography variant="body2" gutterBottom>
            Accettiamo i seguenti metodi di pagamento
          </Typography>
          <Box component="img" src={payments} alt='metodi di pagamento' sx={{ height: 'auto', maxWidth: '90%' }} /> */}
        </Grid>
        <Grid item xs={12} sm={3}>
          {/* <Typography variant="subtitle2" gutterBottom>
            Link utili
          </Typography>
          <Typography variant="caption" gutterBottom>
            <Stack spacing={0.5}>
              <Link href="#" color="inherit">Chi siamo</Link>
              <Link href="#" color="inherit">Risorse gratuite</Link>
            </Stack>
          </Typography> */}
        </Grid>
        <Grid item xs={12} sm={3}>
          {/* <Typography variant="subtitle1" gutterBottom>
            I nostri prodotti
          </Typography>
          <Link href="#" color="inherit">Vedi tutti i prodotti</Link> */}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1" gutterBottom>
            Contatti
          </Typography>
          <Typography variant="body2">
            Per informazioni puoi scrivere a <Link href="mailto:info@fluel.it" color="inherit">info@fluel.it</Link>
          </Typography>
          <Typography variant="body2"><Link href="http://fluel.it" color="inherit">Fluel</Link></Typography>
          {/* <Typography variant="body2">P.I. 04177861202</Typography> */}
          <Typography variant="caption">
            <Stack spacing={0.5}>
              <Link href="\privacy-policy" color="inherit">Privacy Policy</Link>
              <Link href="\privacy-policy" color="inherit">Termini di utilizzo</Link>
            </Stack>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Footer;
import React from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Input, FormHelperText, Checkbox, FormControlLabel, Snackbar } from '@mui/material';
import Navbar from './Navbar';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import Footer from './Footer';


const VerifyEmail = () => {
    return (
        <ThemeProvider theme={theme}>
          <Navbar />
          <Box sx={{ padding: theme.spacing(4), textAlign: 'center' }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Verifica la tua email
            </Typography>
            <Typography variant="h6" component="p">
              Devi verificare la tua email prima di accedere all'applicazione
            </Typography>
          </Box>
          <Footer />
        </ThemeProvider>
      );
};

export default VerifyEmail;
import { initializeApp } from "@firebase/app";
// import { getAnalytics } from "@firebase/analytics";
// import { getStorage } from "@firebase/storage";
// import firebase from 'firebase/app';
// import 'firebase/storage';
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const firebaseConfig ={
  apiKey: "AIzaSyAk02c5IKpS0PP71zkn3jWswa3_lKBk-2c",
  authDomain: "aicards-2f02e.firebaseapp.com",
  projectId: "aicards-2f02e",
  storageBucket: "aicards-2f02e.appspot.com",
  messagingSenderId: "640731790862",
  appId: "1:640731790862:web:48b76038b644d2c99a065f",
  measurementId: "G-FXG3L4ZPD9"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
// const storage = getStorage(app, "gs://aicards-2f02e.appspot.com");
const auth = getAuth(app);
const db = getFirestore(app);

export { db, auth };

import React from 'react';
import { Box, Typography, Button, Grid, Card, CardMedia, CardActions, useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import heroImage from '../../assets/img/Mix Herosection.png'; 
import { Link as Link_ } from 'react-router-dom';


const ProductSalesSection = () => {
  const theme = useTheme();
  const MySvgIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path d="M15.93 17.09c-.18.16-.43.17-.63.06c-.89-.74-1.05-1.08-1.54-1.79c-1.47 1.5-2.51 1.95-4.42 1.95c-2.25 0-4.01-1.39-4.01-4.17c0-2.18 1.17-3.64 2.86-4.38c1.46-.64 3.49-.76 5.04-.93V7.5c0-.66.05-1.41-.33-1.96c-.32-.49-.95-.7-1.5-.7c-1.02 0-1.93.53-2.15 1.61c-.05.24-.25.48-.47.49l-2.6-.28c-.22-.05-.46-.22-.4-.56c.6-3.15 3.45-4.1 6-4.1c1.3 0 3 .35 4.03 1.33C17.11 4.55 17 6.18 17 7.95v4.17c0 1.25.5 1.81 1 2.48c.17.25.21.54 0 .71l-2.06 1.78h-.01m-2.7-6.53V10c-1.94 0-3.99.39-3.99 2.67c0 1.16.61 1.95 1.63 1.95c.76 0 1.43-.47 1.86-1.22c.52-.93.5-1.8.5-2.84m6.93 8.98C18 21.14 14.82 22 12.1 22c-3.81 0-7.25-1.41-9.85-3.76c-.2-.18-.02-.43.25-.29c2.78 1.63 6.25 2.61 9.83 2.61c2.41 0 5.07-.5 7.51-1.53c.37-.16.66.24.32.51m.91-1.04c-.28-.36-1.85-.17-2.57-.08c-.19.02-.22-.16-.03-.3c1.24-.88 3.29-.62 3.53-.33c.24.3-.07 2.35-1.24 3.32c-.18.16-.35.07-.26-.11c.26-.67.85-2.14.57-2.5z" fill="currentColor"/></svg>

  );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p:{xs:theme.spacing(2), md:theme.spacing(6)}, bgcolor: 'background.default' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ maxWidth: {xs:'100%', md:500}, boxShadow: 0 }}>
            <CardMedia
              component="img"
              height="auto"
              image= {heroImage} // Replace with the path to your product image
              alt="Sinergie Generative"
            />
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h5" color="text.secondary" gutterBottom>
            BEST SELLER
          </Typography>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Rajdhani', fontSize: "3rem" }}>
            Sinergie Generative Libro + AI Cards
          </Typography>
          {/* <Typography variant="h3" color="primary" gutterBottom sx={{ textDecoration: 'line-through' }}>
            €60,00
          </Typography>
          <Typography variant="h3" gutterBottom>
            €49,90 (Spedizione incl.)
          </Typography> */}
          <Typography variant="body1" paragraph sx={{pr:{xs:0, md:20}}}>
          Scopri la potenza di un metodo che unisce creatività umana e intelligenza artificiale. Con il nostro libro esclusivo e le 40 AI Cards, trasforma ogni idea in realtà concreta. Ogni pagina e ogni carta sono progettate per ispirarti, guidarti e farti esplorare nuove possibilità. Non è solo un acquisto, è un investimento nel tuo futuro creativo.
          </Typography>
          <ul>
            {["Il manuale di oltre 130 pag. con la spiegazione dei metodi e delle carte in lingua italiana", "Esempi pratici", "40 card digitali in modalità Pro"].map((item, index) => (
              <li key={index}>
                <Typography variant="body1">{item}</Typography>
              </li>
            ))}
          </ul>
          <CardActions>
            <Button 
            size="large" 
            variant="contained" 
            color="primary" 
            startIcon={<MySvgIcon />} 
            sx={{color: 'white'}}
            onClick={() => window.open('https://www.amazon.it/Sinergie-Generative-approccio-strutturato-prodotti/dp/B0D1P5R6WT', '_blank')}
            >
            Acquista subito il libro!
            </Button>
            <Button
            component={Link_}
            to="/request-cards"
            sx={{ my: 1, mx: 1.5, color: "inherit" }}
          >
           Passa a Pro
          </Button>
         
          </CardActions>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Compra su Amazon il libro, <Link href="/signup"> iscriviti </Link> in modalità Free e richiedi in omaggio il passaggio alla versione PRO. 
            Per passare alla versione PRO è importante inviare la ricevuta di acquisto Amazon e compilare il form nella sezione 'Passa a Pro' 
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductSalesSection;
import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MiniMap,
  useEdgesState,
  useNodesState,
  useReactFlow,
  MarkerType,
  useViewport,
  useZoomPanHelper  
} from "react-flow-renderer";
import { ReactFlowProvider } from "react-flow-renderer";
import { useStore} from 'react-flow-renderer'
import "./InfiniteCanvas.css";
import CustomCardNode from "./CustomCardNode";
import PostItNode from "./PostItNode";
import ResizableTextNode from "./ResizableTextNode";

import { doc, getDoc, setDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path if necessary
import { useAuth } from "../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faRefresh,
  faNoteSticky,
  faFont,
  faImage,
  faUndo,
  faFileArrowUp,faRightLeft, faLayerGroup,faObjectGroup,faShuffle
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as SolidIcon } from "../assets/icons/line-solid.svg";
import { ReactComponent as DashedIcon } from "../assets/icons/line-dashed.svg";
import { ReactComponent as DottedIcon } from "../assets/icons/line-dotted.svg";

import ConfirmationModal from "../components/Modal";
import SaveSessionModal from "../components/SaveSessionModal ";
import LoadSessionModal from "../components/LoadSessionModal";
import html2canvas from 'html2canvas';
import { toPng } from 'html-to-image';
import { v4 as uuidv4 } from 'uuid';



const cardNames = [
  { label: "Scoperta Creativa", group: "A", image: "A1.jpg" , back: "cards_back.jpg"},
  { label: "Definizione Del Problema", group: "A", image: "A2.jpg", back: "cards_back.jpg"},
  { label: "Visione Generativa", group: "A", image: "A3.jpg", back: "cards_back.jpg" },
  { label: "Pensiero Laterale nell'AI", group: "A", image: "A4.jpg", back: "cards_back.jpg" },
  { label: "AI Customer Journey", group: "A", image: "A5.jpg", back: "cards_back.jpg" },
  { label: "Narrativa e Storytelling con l'AI", group: "A", image: "A6.jpg", back: "cards_back.jpg" },
  { label: "AI nella Salute Mentale", group: "A", image: "A7.jpg", back: "cards_back.jpg" },
  { label: "AI e Arte Digitale", group: "A", image: "A8.jpg", back: "cards_back.jpg" },
  { label: "AI Etica per Designer", group: "B", image: "B1.jpg", back: "cards_back.jpg" },
  { label: "Strategie di Implementazione", group: "B", image: "B2.jpg", back: "cards_back.jpg" },
  { label: "Etica e impatto", group: "B", image: "B3.jpg", back: "cards_back.jpg" },
  { label: "Misurazione Del Successo", group: "B", image: "B4.jpg", back: "cards_back.jpg" },
  { label: "AI per il Social Good", group: "B", image: "B5.jpg", back: "cards_back.jpg" },
  { label: "Valutazione dell'Usabilità", group: "B", image: "B6.jpg", back: "cards_back.jpg" },
  { label: "Intelligenza Emotiva e AI", group: "B", image: "B7.jpg", back: "cards_back.jpg" },
  { label: "Customization", group: "B", image: "B8.jpg", back: "cards_back.jpg" },
  { label: "Innovazione Continua", group: "C", image: "C1.jpg", back: "cards_back.jpg" },
  { label: "Community e Collaborazione", group: "C", image: "C2.jpg", back: "cards_back.jpg" },
  { label: "Adattabilità e AI", group: "C", image: "C3.jpg", back: "cards_back.jpg" },
  { label: "Scalabilità delle Soluzioni", group: "C", image: "C4.jpg", back: "cards_back.jpg" },
  { label: "Futuro del Design con AI", group: "C", image: "C5.jpg", back: "cards_back.jpg" },
  { label: "Sostenibilità nell'AI", group: "C", image: "C6.jpg", back: "cards_back.jpg" },
  { label: "AI e il nuovo lavoro", group: "C", image: "C7.jpg", back: "cards_back.jpg" },
  { label: "AI Open Source", group: "C", image: "C8.jpg", back: "cards_back.jpg" },
  { label: "AI Design Thinking", group: "D", image: "D1.jpg", back: "cards_back.jpg" },
  { label: "Costruzione di prototipi", group: "D", image: "D2.jpg", back: "cards_back.jpg" },
  { label: "Data Driven Design", group: "D", image: "D3.jpg", back: "cards_back.jpg" },
  { label: "Feedback e Iterazione", group: "D", image: "D4.jpg", back: "cards_back.jpg" },
  { label: "Prototipazione Rapida con AI", group: "D", image: "D5.jpg", back: "cards_back.jpg" },
  { label: "Testing A/B con AI", group: "D", image: "D6.jpg", back: "cards_back.jpg" },
  { label: "Simulazioni AI", group: "D", image: "D7.jpg", back: "cards_back.jpg" },
  { label: "Risoluzione Creativa dei Problemi", group: "D", image: "D8.jpg", back: "cards_back.jpg" },
  { label: "Design Inclusivo", group: "E", image: "E1.jpg", back: "cards_back.jpg" },
  { label: "Interazione Uomo Macchina", group: "E", image: "E2.jpg", back: "cards_back.jpg" },
  { label: "Rispetto della Privacy nell'AI", group: "E", image: "E3.jpg", back: "cards_back.jpg" },
  { label: "Bias e Fairness nell'AI", group: "E", image: "E4.jpg", back: "cards_back.jpg" },
  { label: "Design per la Voice AI", group: "E", image: "E5.jpg", back: "cards_back.jpg" },
  { label: "AI Affidabile", group: "E", image: "E6.jpg", back: "cards_back.jpg" },
  { label: "Adattamento Culturale", group: "E", image: "E7.jpg", back: "cards_back.jpg" },
  { label: "Intelligenza Collaborativa", group: "E", image: "E8.jpg", back: "cards_back.jpg" },
];

const freeUserCardLabels = [
  "Scoperta Creativa",
  "Definizione Del Problema",
  "Interazione Uomo Macchina",
  "Data Driven Design",
  "Intelligenza Emotiva e AI",
  "AI Customer Journey",
  "Feedback e Iterazione",
  "Adattamento Culturale",
  "Design per la Voice AI",
  "Rispetto della Privacy nell'AI"
];


const nodeTypes = {
  customCardNode: CustomCardNode,
  postItNode: PostItNode,
  resizableText: ResizableTextNode,
};

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(
  require.context("../assets/img/cards", false, /\.(jpg|jpe?g|svg)$/)
);

const backImage = require("../assets/img/cards/cards_back.jpg");





// const initialNodes = generateInitialNodes();

const edgeTypes = [
  { type: "default", label: "Default" },
  { type: "straight", label: "Straight" },
  { type: "step", label: "Step" },
  { type: "smoothstep", label: "Smooth Step" },
];

const edgeStyles = [
  { style: "solid", label: "Solid" },
  { style: "dashed", label: "Dashed" },
  { style: "dotted", label: "Dotted" },
];

const styleIcons = {
  solid: <SolidIcon style={{ width: "10px", height: "10px" }} />,
  dashed: <DashedIcon style={{ width: "10px", height: "10px" }} />,
  dotted: <DottedIcon style={{ width: "10px", height: "10px" }} />,
};

const ColorIcon = ({ color, label, isActive, onClick }) => (
  <div
    style={{
      width: "10px",
      height: "10px",
      backgroundColor: color,
      display: "inline-block",
      margin: "0 5px",
      cursor: "pointer",
      border: isActive ? "1px solid #343454 " : "1px solid transparent",
    }}
    onClick={() => onClick(label)}
  />
);


const generateInitialNodes = (role) => {
  const nodes = [];
  const cardWidth = 200;
  const cardHeight = 360;
  const startX = 50;
  const startY = 50;

  let currentRow = -1;
  let currentLetter = "";


  // Determine which cards to include based on user role
  const availableCards = role === 'Free'
    ? cardNames.filter(card => freeUserCardLabels.includes(card.label))
    : cardNames; // Pro and Admin users get all cards


  // Logic for Free users (2 rows of 5 cards)
  if (role === 'Free') {
    availableCards.forEach((card, index) => {
      const row = Math.floor(index / 5);
      const column = index % 5;
      const x = startX + column * (cardWidth + 10);
      const y = startY + row * (cardHeight + 10);

      nodes.push({
        id: `card-${index + 1}`,
        type: "customCardNode",
        data: {
          label: card.label,
          image: images[card.image],
          backImage: backImage,
          group: card.group,
          isFlipped: false,
        },
        position: { x, y },
      });
    });
  } 
  // Logic for Pro and Admin users (arrange by group)
  else {
    availableCards.forEach((card, index) => {
      const letter = card.group;
      if (letter !== currentLetter) {
        currentLetter = letter;
        currentRow++;
      }

      const column = index % 8;
      const x = startX + column * (cardWidth + 10);
      const y = startY + currentRow * (cardHeight + 10);

      nodes.push({
        id: `card-${index + 1}`,
        type: "customCardNode",
        data: {
          label: card.label,
          image: images[card.image],
          backImage: backImage,
          group: card.group,
          isFlipped: false,
        },
        position: { x, y },
      });
    });
  }

  return nodes;
};

// Shuffle function using Fisher-Yates algorithm
const shuffleArray = (array) => {
  let shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const InfiniteCanvas = () => {
  
  const { currentUser, role } = useAuth();
  const userId = currentUser.uid;
  
  // Inside your component
  const [sessionId, setSessionId] = useState(uuidv4());
  const [sessionName, setSessionName] = useState("default_name");
  
 
  
  const [nodes, setNodes, onNodesChange] = useNodesState(generateInitialNodes(role));

   // Function to handle card node updates
   const onCardNodeUpdate = useCallback((nodeId, newData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return { ...node, data: newData };
        }
        return node;
      })
    );
  }, [setNodes]);

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  // const [newNotePosition, setNewNotePosition] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeGroups, setActiveGroups] = useState([
    "A",
    "B",
    "C",
    "D",
    "E",
    "note",
  ]);
  const [currentEdgeType, setCurrentEdgeType] = useState(edgeTypes[0]);
  const [currentEdgeStyle, setCurrentEdgeStyle] = useState(edgeStyles[0]);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance_, setReactFlowInstance] = useState(null);
  const { fitView, project } = useReactFlow(); /*sasa*/
  const reactFlowInstance = useReactFlow();
  const { x, y, zoom } = useViewport();
  
  // const { currentUser } = useAuth();
  
  
  // const nodes = useNodes();
  const { setViewport } = useReactFlow(); /*sasa*/
  // console.log('-->user id ' + userId)

  // funzioni per salvataggio e recupero sessione

  const [saveFeedback, setSaveFeedback] = useState("");
  const [isConfirmOverwriteModalOpen, setIsConfirmOverwriteModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [existingSessions, setExistingSessions] = useState([]);

  const resetNodePositions = () => {
    const initialPositions = generateInitialPositions();
    setNodes((nds) =>
      nds.map((node, index) => {
        if (node.type === "customCardNode") {
          return {
            ...node,
            position: initialPositions[index],
          };
        }
        return node;
      })
    );
  };

  // Initialize nodes only on the first render
  useEffect(() => {
    setNodes((nds) =>
        nds.map((node) => {
            if (node.type === "customCardNode" && !node.data.onCardNodeUpdate) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        onCardNodeUpdate,  // Attach the onCardNodeUpdate function
                    },
                };
            }
            return node;
        })
    );
}, [onCardNodeUpdate, setNodes]);


  //Initial Positions
  const generateInitialPositions = () => {
    const positions = [];
    const cardWidth = 200;
    const cardHeight = 360;
    const startX = 50;
    const startY = 50;
  
    let currentRow = -1;
    let currentLetter = "";
  
    cardNames.forEach((card, index) => {
      const letter = card.group;
      if (letter !== currentLetter) {
        currentLetter = letter;
        currentRow++;
      }
  
      const column = index % 8;
      const x = startX + column * (cardWidth + 10);
      const y = startY + currentRow * (cardHeight + 10);
  
      positions.push({ x, y });
    });
  
    return positions;
  };
  

  //Initial Positions


  const handleOpenSaveModal = async () => {
    const sessions = await getUserSessions(userId);
    setExistingSessions(sessions);
    setIsSaveModalOpen(true);
};

  const handleSaveSession = (sessionId, description) => {
    saveUserSession(sessionId, description);
    setIsSaveModalOpen(false);
  };

  const handleOpenLoadModal = async () => {
    try {
      const sessions = await getUserSessions(userId);
      setExistingSessions(sessions); // Store sessions to display in the dropdown
      setIsLoadModalOpen(true); // Open the modal
    } catch (error) {
      console.error('Error fetching sessions:', error.message);
    }
  };
  

  const handleCloseSaveModal = () => {
    setIsSaveModalOpen(false);
  };

  const handleCloseLoadModal = () => {
    setIsLoadModalOpen(false);
  };

  // LOAD and Save Session

  const handleConfirmLoad = () => {
    loadUserSession(); // Perform the save operation
    handleCloseLoadModal();
  };

  // Function to handle the confirmation and save the session
  const handleConfirmOverwrite = () => {
    saveUserSession(sessionName, ""); // Save the session with the same ID and name
    setIsConfirmOverwriteModalOpen(false);
  };


  const onNodeUpdate = useCallback((nodeId, newText) => {
    console.log(`Updating node ${nodeId} with new text: ${newText}`);
    setNodes(prevNodes => prevNodes.map(node => {
        if (node.id === nodeId) {
            return {
                ...node,
                data: { ...node.data, label: newText }
            };
        }
        return node;
    }));
}, [setNodes]);








const loadUserSession = async (selectedSessionId) => {
  const sessionRef = doc(db, 'userSessions', userId, 'sessions', selectedSessionId);
  try {
    const docSnap = await getDoc(sessionRef);
    if (docSnap.exists()) {
      const sessionData = docSnap.data();
      const rehydratedNodes = sessionData.nodes.map((node) => {
        if (node.type === 'customCardNode') {
          return {
            ...node,
            data: {
              ...node.data,
              onCardNodeUpdate: onCardNodeUpdate,  // Re-add the onCardNodeUpdate function
              isFlipped: node.data.isFlipped,      // Ensure isFlipped is loaded
            },
          };
        } else {
          return {
            ...node,
            data: {
              ...node.data,
              onNodeUpdate: onNodeUpdate,  // Re-add the onNodeUpdate function
            },
          };
        }
      });

      setSessionId(sessionData.id);
      setSessionName(sessionData.name);
      setNodes(rehydratedNodes);
      setEdges(sessionData.edges);
    } else {
      console.error('No such document!');
    }
  } catch (error) {
    console.error('Error loading user session:', error.message);
  }
};




  const cleanDataForFirestore = (data) => {
    return JSON.parse(
      JSON.stringify(data, (key, value) => {
        if (typeof value === "function") {
          return undefined;
        }
        return value;
      })
    );
  };

  const handleConfirmSave = () => {
    saveUserSession(); // Perform the save operation
    handleCloseSaveModal();
  };

  const getUserSessions = async (userId) => {
    const sessionsCollectionRef = collection(db, 'userSessions', userId, 'sessions');
    const snapshot = await getDocs(sessionsCollectionRef);
  
    const sessions = [];
    snapshot.forEach(doc => {
      sessions.push({ id: doc.id, ...doc.data() });
    });
  
    return sessions;
  };

  const saveUserSession = async (name, description) => {
    try {
      const serializableNodes = nodes.map((node) => {
        // Check node type and sanitize the appropriate update function
        if (node.type === 'customCardNode') {
          return {
            ...node,
            data: {
              ...node.data,
              onCardNodeUpdate: 'undefined',  // Remove onCardNodeUpdate function
              isFlipped: node.data.isFlipped, // Ensure isFlipped is included
            },
          };
        } else {
          return {
            ...node,
            data: {
              ...node.data,
              onNodeUpdate: 'undefined',  // Remove onNodeUpdate function
            },
          };
        }
      });

      await setDoc(doc(db, 'userSessions', userId, 'sessions', sessionId), {
        id: sessionId,
        name,
        description,
        timestamp: Date.now(),
        nodes: serializableNodes,
        edges,
      });

      setSessionName(name);
      console.log('Session saved successfully');
      setSaveFeedback('Session saved successfully!');
    } catch (error) {
      console.error('Error saving session:', error.message);
      setSaveFeedback(`Error saving session: ${error.message}`);
    }
  };

  


  //------------------------
  // END LOAD and Save Session

  
  const onConnect = useCallback(
    (params) => {
      const newEdge = {
        ...params,
        type: currentEdgeType.type,
        style: { strokeDasharray: getStrokeDashArray(currentEdgeStyle.style) },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: "#888",
        },
      };
      setEdges((eds) => addEdge(newEdge, eds));
      // saveUserSession();
    },
    [setEdges, currentEdgeType, currentEdgeStyle.style, saveUserSession]
  );

  const getStrokeDashArray = (style) => {
    switch (style) {
      case "dashed":
        return "5, 5";
      case "dotted":
        return "1, 5";
      default:
        return "";
    }
  };

  const toggleEdgeType = () => {
    const currentIndex = edgeTypes.findIndex(
      (et) => et.type === currentEdgeType.type
    );
    const nextIndex = (currentIndex + 1) % edgeTypes.length;
    setCurrentEdgeType(edgeTypes[nextIndex]);
  };

  const toggleEdgeStyle = () => {
    const currentIndex = edgeStyles.findIndex(
      (es) => es.style === currentEdgeStyle.style
    );
    const nextIndex = (currentIndex + 1) % edgeStyles.length;
    setCurrentEdgeStyle(edgeStyles[nextIndex]);
  };

  const updateSelectedEdgeStyle = () => {
    if (selectedEdge) {
      setEdges((eds) =>
        eds.map((edge) =>
          edge.id === selectedEdge.id
            ? {
                ...edge,
                style: {
                  strokeDasharray: getStrokeDashArray(currentEdgeStyle.style),
                },
                type: currentEdgeType.type,
              }
            : edge
        )
      );
      setSelectedEdge(null); // Reset selected edge after updating style
    }
  };


  const onEdgeClick = useCallback((event, edge) => {
    setSelectedEdge(edge);
  }, []);

  const toggleGroup = useCallback((group) => {
    setActiveGroups((prev) =>
      prev.includes(group) ? prev.filter((g) => g !== group) : [...prev, group]
    );
  }, []);

  // console.log("Nodes data structure before filtering:", nodes);
  // console.log("Is nodes an array?", Array.isArray(nodes));

  const filteredNodes = nodes.filter(
    (node) =>
      node.data.label.toLowerCase().includes(searchTerm.toLowerCase()) &&
      activeGroups.includes(node.data.group)
  );

  const addStickyNote = useCallback(() => {
    const newNoteId = `note-${Date.now()}`;
    const newNote = {
      id: newNoteId,
      type: "postItNode",
      position: { x: 250, y: 150 },
      data: {
        label: "New Sticky Note",
        group: "note",
        id: newNoteId,
        onNodeUpdate: onNodeUpdate,
      },
    };

    setNodes((currentNodes) => [...currentNodes, newNote]);
  }, [setNodes, onNodeUpdate]); // Include onNodeUpdate in dependencies

  const onLoad = useCallback((rfInstance) => {
    // console.log("React Flow Instance Loaded");
    setReactFlowInstance(rfInstance);
  }, []);

  const updateNodeText = (nodeId, newText) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return { ...node, data: { ...node.data, label: newText } };
        }
        return node;
      })
    );
  };

  // memo(({ id, data, isConnectable, selected, onNodeUpdate })
  const addResizableTextBox = () => {
    const newNode = {
      id: `text-box-${Date.now()}`,
      type: "resizableText",
      position: { x: Math.random() * 400, y: Math.random() * 400 },
      data: {
        label: "Edit text...",
        group: "note",
        onNodeUpdate: onNodeUpdate,
      },
    };

    // Directly set the new node
    setNodes((nds) => [...nds, newNode]);
  };

  //---------backflip all cards
  const flipAllCards = useCallback(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.type === "customCardNode") {
          return {
            ...node,
            data: {
              ...node.data,
              isFlipped: !node.data.isFlipped,  // Toggle the isFlipped property
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

//---------backflip all cards

//------CREATE DECK
const createDeck = useCallback(() => {
  const topLeftCorner = { x: 50, y: 50 }; // Position at the top left corner (adjust as needed)
  
  setNodes((nds) =>
    nds.map((node, index) => {
      if (node.type === "customCardNode") {
        return {
          ...node,
          position: {
            x: topLeftCorner.x + index * 1, // Slight offset for visibility
            y: topLeftCorner.y + index * 1,
          },
        };
      }
      return node;
    })
  );
}, [setNodes]);
//------CREATE DECK

const createDecks = () => {
  const deckPositions = {
    A: { x: 50, y: 50 },
    B: { x: 300, y: 50 },
    C: { x: 550, y: 50 },
    D: { x: 800, y: 50 },
    E: { x: 1050, y: 50 },
  };

  const shiftAmount = 1; // The amount of shift for each card in the deck

  // Create a mapping to track the number of cards processed per group
  const groupCardCounts = { A: 0, B: 0, C: 0, D: 0, E: 0 };

  setNodes((nds) =>
    nds.map((node) => {
      const group = node.data.group;

      if (deckPositions[group]) {
        const currentShift = groupCardCounts[group] * shiftAmount;

        const updatedNode = {
          ...node,
          position: {
            x: deckPositions[group].x + currentShift,
            y: deckPositions[group].y + currentShift,
          },
          style: {
            ...node.style,
            zIndex: groupCardCounts[group], // Ensure correct stacking order
          },
        };

        groupCardCounts[group] += 1; // Increment the count for the current group

        return updatedNode;
      }

      return node;
    })
  );
};


  // Shuffle the deck and update the state
  const handleShuffleDeck = () => {
    setNodes((nds) => shuffleArray(nds));
    resetNodePositions()
  };

  const reorderNodes = (nodes, cardNames) => {
    // Create a mapping from card label to the original order based on cardNames array
    const cardOrderMap = cardNames.reduce((acc, card, index) => {
      acc[card.label] = index;
      return acc;
    }, {});
  
    // Sort the nodes array based on the original order in cardOrderMap
    return nodes.slice().sort((a, b) => {
      return cardOrderMap[a.data.label] - cardOrderMap[b.data.label];
    });
  };

   // Function to reorder the deck
   const handleReorderDeck = () => {
    setNodes((nds) => reorderNodes(nds, cardNames));
    resetNodePositions()
  };
//------CREATE DECK


  //----------EXPORT TO PNG--------------
  
    const captureFullCanvas = useCallback(() => {
      const flowElement = document.querySelector('.react-flow');
      if (!flowElement) {
        console.error('React Flow element not found');
        return;
      }
    
      // Hide the minimap and zoom controls during capture
      const minimapElement = flowElement.querySelector('.react-flow__minimap');
      const controlsElement = flowElement.querySelector('.react-flow__controls');
      
      if (minimapElement) {
        minimapElement.style.visibility = 'hidden';
      }
      
      if (controlsElement) {
        controlsElement.style.visibility = 'hidden';
      }
    
      const nodes = flowElement.querySelectorAll('.react-flow__node');
      if (nodes.length === 0) {
        console.error('No nodes found in the React Flow container');
        return;
      }
    
      let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
    
      nodes.forEach(node => {
        const rect = node.getBoundingClientRect();
        console.log(`Node ID: ${node.id}, Rect:`, rect);
        minX = Math.min(minX, rect.left + window.scrollX);
        maxX = Math.max(maxX, rect.right + window.scrollX);
        minY = Math.min(minY, rect.top + window.scrollY);
        maxY = Math.max(maxY, rect.bottom + window.scrollY);
      });
    
      console.log(`Computed canvas bounds: minX=${minX}, minY=${minY}, maxX=${maxX}, maxY=${maxY}`);
    
      const padding = 50; // Add padding around the canvas
      minX -= padding;
      minY -= padding;
      maxX += padding;
      maxY += padding;
    
      const width = maxX - minX;
      const height = maxY - minY;
    
      console.log(`Canvas dimensions with padding: width=${width}, height=${height}`);
    
      // Apply a transformation to the viewport to pan to the top-left corner
      const viewport = flowElement.querySelector('.react-flow__viewport');
      viewport.style.transform = `translate(${Math.abs(minX)}px, ${Math.abs(minY)}px) scale(1)`;
    
      setTimeout(() => {
        toPng(flowElement, {
          backgroundColor: '#ffffff',
          width: width,
          height: height,
          style: {
            width: `${width}px`,
            height: `${height}px`,
            transform: `translate(0px, 0px) scale(1)` // No additional transform during capture
          },
        }).then(dataUrl => {
          const link = document.createElement('a');
          link.download = 'full-canvas-capture.png';
          link.href = dataUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          console.log('Canvas captured and downloaded.');
    
          // Restore the minimap and controls visibility
          if (minimapElement) {
            minimapElement.style.visibility = 'visible';
          }
          if (controlsElement) {
            controlsElement.style.visibility = 'visible';
          }
    
          // Restore the original viewport transformation
          viewport.style.transform = `translate(${minX}px, ${minY}px) scale(1)`;
    
        }).catch(error => {
          console.error('Failed to capture the full canvas:', error);
    
          // Restore the minimap and controls visibility even if capture fails
          if (minimapElement) {
            minimapElement.style.visibility = 'visible';
          }
          if (controlsElement) {
            controlsElement.style.visibility = 'visible';
          }
        });
      }, 500); // Wait for transformations to stabilize
    
    }, [nodes]);
    
    
    
    
    
    
    
    
    
    
    
    
                
    //----------EXPORT TO PNG--------------
                

  // const print_nodes = () => {
  //   console.log(nodes);
  // };

  return (
    <div ref={reactFlowWrapper} style={{ width: "100%", height: "100%" }}>
      <ReactFlowProvider>
        <div className="canvas-container">
          <div style={{ margin: "10px" }}>
           
            <button
              className="custom-button"
              title="Load a Saved Session"
              onClick={handleOpenLoadModal}
              style={{ marginLeft: "10px" }}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </button>
            <LoadSessionModal
              isOpen={isLoadModalOpen}
              onClose={handleCloseLoadModal}
              onLoad={loadUserSession}
              existingSessions={existingSessions}
            />

            <button
              className="custom-button"
              title="Save"
              onClick={() => setIsConfirmOverwriteModalOpen(true)}
              style={{ marginLeft: "10px" }}
            >
              <FontAwesomeIcon icon={faFileArrowUp} />
            </button>
            <ConfirmationModal
              isOpen={isConfirmOverwriteModalOpen}
              onClose={() => setIsConfirmOverwriteModalOpen(false)}
              onConfirm={handleConfirmOverwrite}
              message="Are you sure you want to overwrite the current session?"
            />

            <button
              className="custom-button"
              title="Save AS"
              onClick={handleOpenSaveModal}
              style={{ marginLeft: "10px" }}
            >
              <FontAwesomeIcon icon={faSave} />
            </button>

            <SaveSessionModal
              isOpen={isSaveModalOpen}
              onClose={() => setIsSaveModalOpen(false)}
              onConfirm={handleSaveSession}
              existingSessions={existingSessions}
            />




            <button
              className="custom-button"
              onClick={captureFullCanvas}
              title="Export to Png"
              style={{ marginLeft: "10px" }}
            >
              <FontAwesomeIcon icon={faImage} />
            </button>

            <span
              style={{
                borderLeft: "2px solid #ccc",
                height: "10px",
                margin: "0 30px",
              }}
            ></span>

            <button
              className="custom-button"
              onClick={addStickyNote}
              title="Add a sticky note"
              style={{ marginLeft: "0px" }}
            >
              <FontAwesomeIcon icon={faNoteSticky} />
            </button>

            <button
              className="custom-button"
              onClick={addResizableTextBox}
              title="Add Textbox"
              style={{ marginLeft: "10px" }}
            >
              <FontAwesomeIcon icon={faFont} />
            </button>
            {/* <button onClick={print_nodes} style={{ margin: "10px" }}>
            P_Nodes
          </button> */}

            <input
              type="text"
              placeholder="Search cards..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginLeft: "10px" }}
            />
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <ColorIcon
                color="#67F6FF"
                label="A"
                isActive={activeGroups.includes("A")}
                onClick={toggleGroup}
              />
              <ColorIcon
                color="#68426D"
                label="B"
                isActive={activeGroups.includes("B")}
                onClick={toggleGroup}
              />
              <ColorIcon
                color="#C994FF"
                label="C"
                isActive={activeGroups.includes("C")}
                onClick={toggleGroup}
              />
              <ColorIcon
                color="#50B84D"
                label="D"
                isActive={activeGroups.includes("D")}
                onClick={toggleGroup}
              />
              <ColorIcon
                color="#BFB570"
                label="E"
                isActive={activeGroups.includes("E")}
                onClick={toggleGroup}
              />
              <ColorIcon
                color="yellow"
                label="note"
                isActive={activeGroups.includes("note")}
                onClick={toggleGroup}
              />
            </div>
            <button
              className="custom-button"
              onClick={toggleEdgeType}
              style={{ marginLeft: "10px" }}
            >
              Edge Type: {currentEdgeType.label}
            </button>
            <button
              className="custom-button"
              onClick={toggleEdgeStyle}
              style={{ marginLeft: "10px" }}
            >
              {styleIcons[currentEdgeStyle.style]}{" "}
              {/* Display the icon corresponding to the current style */}
            </button>

            <button
              className="custom-button"
              onClick={updateSelectedEdgeStyle}
              style={{ marginLeft: "10px" }}
            >
              Update Selected Edge
            </button>
            <button
              className="custom-button"
              title="Reset Node Positions"
              onClick={handleReorderDeck}
              style={{ marginLeft: "10px" }}
            >
              <FontAwesomeIcon icon={faUndo} /> {/* Use an appropriate icon */}
            </button>

            <button
            className="custom-button"
            title="Flip All Cards"
            onClick={flipAllCards}
            style={{ marginLeft: "10px" }}
          >
            <FontAwesomeIcon icon={faRightLeft} /> {/* Use a relevant icon */}
          </button>

          <button
            className="custom-button"
            title="Create Deck"
            onClick={createDeck}
            style={{ marginLeft: "10px" }}
          >
            <FontAwesomeIcon icon={faLayerGroup} /> {/* Use a relevant icon */}
          </button>

          <button
            className="custom-button"
            title="Create Decks"
            onClick={createDecks}
            style={{ marginLeft: "10px" }}
          >
            <FontAwesomeIcon icon={faObjectGroup} /> {/* Use a relevant icon */}
          </button>

          <button 
          className="custom-button"
          title="Shuffle Decks"
          onClick={handleShuffleDeck}
          style={{ marginLeft: "10px" }}
          >
           <FontAwesomeIcon icon={faShuffle} />
           </button>




            <div className="session-info" >
              Session ID: {sessionId} - {sessionName}
            </div>
            

            <div>{saveFeedback}</div>
            {/* <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" /> */}
          </div>

          <div className="canvas">
            <ReactFlow
              onLoad={onLoad}
              nodes={filteredNodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onEdgeClick={onEdgeClick}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              
            >
              <MiniMap />
              <Controls />
              <Background />
            </ReactFlow>
          </div>
        </div>
      </ReactFlowProvider>
    </div>
  );
};

export default InfiniteCanvas;

// src/firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAk02c5IKpS0PP71zkn3jWswa3_lKBk-2c",
    authDomain: "aicards-2f02e.firebaseapp.com",
    projectId: "aicards-2f02e",
    storageBucket: "aicards-2f02e.appspot.com",
    messagingSenderId: "640731790862",
    appId: "1:640731790862:web:48b76038b644d2c99a065f",
    measurementId: "G-FXG3L4ZPD9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };

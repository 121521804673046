import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import InfiniteCanvas from './InfiniteCanvas';
import { ReactFlowProvider } from 'react-flow-renderer';

function Dashboard() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <ReactFlowProvider>
      <div style={{ padding: '10px' }}>
                <InfiniteCanvas />

      </div>
      </ReactFlowProvider>
      <div className="footer_small">
      <span>Version: 1.0</span>
    </div>
    </ThemeProvider>
  );
}

export default Dashboard;
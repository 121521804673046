import React, { useState } from 'react';

const PostItNode = ({ data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(data.label);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };
  // console.log("Node data in PostItNode:", data);

  const handleBlur = () => {
    if (text !== data.label) {
        data.onNodeUpdate(data.id, text);
    }
    setIsEditing(false);
};

  return (
      <div
          style={{
              padding: '10px',
              backgroundColor: '#f4fda1',
              border: '1px solid #e1e1d6',
              color: '#333',
              boxShadow: '0px 6px 8px rgba(0,0,0,0.1)',
              borderRadius: '2px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '150px',
              height: '150px',
              textAlign: 'center',
              cursor: 'grab',
              fontSize: '12px',
              fontWeight: '500',
          }}
          onDoubleClick={handleDoubleClick}
      >
          {isEditing ? (
              <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              onBlur={handleBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault(); // Prevents a new line when Enter is pressed without Shift
                  handleBlur();  // Commit changes and exit editing
                }
              }}
              autoFocus
              style={{
                width: '100%',
                height: '100%',        // Adjust height as needed or use rows attribute
                border: 'none',
                outline: 'none',
                padding: '5px',
                textAlign: 'center',
                fontSize: '12px',
                fontFamily: "arial",
                backgroundColor: 'transparent',
                resize: 'none',        // Prevents resizing the textarea
                overflow: 'auto'       // Adds scrollbars if text overflows
              }}
            />
          ) : (
              <span>{text}</span>
          )}
      </div>
  );
};

export default PostItNode;

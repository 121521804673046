import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Input, FormHelperText, Checkbox, FormControlLabel, Snackbar } from '@mui/material';
import Navbar from './components/Navbar';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Footer from './components/Footer';
import LibroCarte from './assets/img/Mix Herosection.png';
import { useAuth } from './contexts/AuthContext';
import { getFirestore, collection, addDoc } from "@firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";

const RequestCards = () => {
  const province = ["AG", "AL", "AN", "AO", "AR", "AP", "AT", "AV", "BA", "BT", "BL", "BN", "BG", "BI", "BO", "BZ", "BS", "BR", "CA", "CL", "CB", "CI", "CE", "CT", "CZ", "CH", "CO", "CS", "CR", "KR", "CN", "EN", "FM", "FE", "FI", "FG", "FC", "FR", "GE", "GO", "GR", "IM", "IS", "SP", "AQ", "LT", "LE", "LC", "LI", "LO", "LU", "MC", "MN", "MS", "MT", "ME", "MI", "MO", "MB", "NA", "NO", "NU", "OT", "OR", "PD", "PA", "PR", "PV", "PG", "PU", "PE", "PC", "PI", "PT", "PN", "PZ", "PO", "RG", "RA", "RC", "RE", "RI", "RN", "RM", "RO", "SA", "VS", "SS", "SV", "SI", "SR", "SO", "TA", "TE", "TR", "TO", "OG", "TP", "TN", "TV", "TS", "UD", "VA", "VE", "--"];
  
  const { currentUser, role } = useAuth();
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);  // New state for submission success

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!privacyAccepted) {
      alert("Devi accettare la privacy policy per proseguire.");
      return;
    }

    setIsSubmitting(true);

    const formData = {
      nome: event.target.elements.nome.value,
      cognome: event.target.elements.cognome.value,
      via: event.target.elements.via.value || '', // optional
      citta: event.target.elements.citta.value,
      cap: event.target.elements.cap.value || '', // optional
      provincia: event.target.elements.provincia.value,
      email: currentUser.email, // using the authenticated user's email
      ordine: event.target.elements.ordine.value || '', // optional order number
    };

    // File for image
    const file = event.target.image.files[0]; // 'image' is the name attribute of the file input
    
    if (!file) {
      console.log("No file selected");
      setIsSubmitting(false);
      return;
    }

    if (file.size > 1 * 1024 * 1024) { // 1 MB limit
      alert("File is too large. Max size is 1MB.");
      setIsSubmitting(false);
      return;
    }

    try {
      // Initialize Firestore and Storage
      const db = getFirestore();
      const storage = getStorage();

      // Create a storage ref with user's UID in the path
      const storageRef = ref(storage, `uploads/${currentUser.uid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        }, 
        (error) => {
          console.error('Upload failed', error);
          setIsSubmitting(false);
        }, 
        async () => {
          // Handle successful uploads on complete
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const docRef = await addDoc(collection(db, "submissions"), {
            ...formData,
            imageUrl: downloadURL,
            createdAt: new Date(),
            userId: currentUser.uid, // save the user's ID for reference
            pending:true
          });
          console.log("Document written with ID: ", docRef.id);
          setIsSubmitting(false);
          setSubmissionSuccess(true);  // Set submission success to true
          setSnackbarOpen(true);
        }
      );
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) { // 5 MB limit
      alert("File is too large. Max size is 5MB.");
      event.target.value = "";
    } else {
      // Handle the file upload process here
    }
  };

  if (role === 'pro') {
    return (
      <ThemeProvider theme={theme}>
        <Navbar />
        <Box sx={{ padding: theme.spacing(4), textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Sei già un utente Pro
          </Typography>
          <Typography variant="h6" component="p">
            Hai già accesso a tutte le AI Cards. Grazie per essere un membro Pro!
          </Typography>
        </Box>
        <Footer />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Grid
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          px: theme.spacing(20),
          p: 2,
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
        }}
      >
        <Box sx={{ flexShrink: 0, minWidth: 250, padding: theme.spacing(2) }}>
          <Box
            component="img"
            src={LibroCarte}
            alt="Description"
            sx={{ width: "100%", maxWidth: 500, height: "auto" }}
          />
        </Box>
        {submissionSuccess ? ( // If submission is successful, show the thank you message
          <Box sx={{ textAlign: 'center', width: '100%', py: theme.spacing(4) }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Grazie! La tua richiesta è stata inviata con successo.
            </Typography>
            <Typography variant="h6" component="p">
              Riceverai una notifica nell'area messaggi una volta che il tuo account è stato aggiornato a Pro.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ flex: 1, px: theme.spacing(6), py: theme.spacing(4) }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Passa a Pro e usa tutte le AI Cards
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Carica una foto o una scansione della ricevuta di acquisto di Amazon
              per comprovare il tuo acquisto e compila i campi con i dati. 
              Appena verificheremo il tutto il tuo profilo sarà convertito in Pro e potrai usare tutte le carte senza limiti.
              Importante, nella copia dell'ordine accertati che sia visibile il numero ordine e visibile il titolo del libro Sinergie Generative. 
            </Typography>

            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField label="Nome" name="nome" variant="outlined" required />
                <TextField label="Cognome" name="cognome" variant="outlined" required />
                <TextField label="Via" name="via" variant="outlined" />
                <TextField label="Città" name="citta" variant="outlined" required />
                <TextField label="CAP" name="cap" variant="outlined" />
                <FormControl variant="outlined" required>
                  <InputLabel>Provincia</InputLabel>
                  <Select name="provincia" label="Provincia">
                    {province.map((prov, index) => (
                      <MenuItem key={index} value={prov}>
                        {prov}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Email Address"
                  name="email"
                  variant="outlined"
                  type="email"
                  value={currentUser.email} // Display user's email
                  disabled // Make it non-editable
                />

                <TextField
                  label="Numero Ordine (Optional)"
                  name="ordine"
                  variant="outlined"
                />

                <FormControl>
                  <Input
                    required
                    type="file"
                    name="image"
                    inputProps={{ accept: "image/*" }}
                    onChange={handleFileChange}
                  />
                  <FormHelperText>Max size: 1MB</FormHelperText>
                </FormControl>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyAccepted}
                      onChange={(e) => setPrivacyAccepted(e.target.checked)}
                      name="privacy"
                      required
                    />
                  }
                  label="Accetto la privacy policy"
                />

                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Invia Richiesta"}
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message="Richiesta inviata con successo!"
      />

      <Footer />
    </ThemeProvider>
  );
};

export default RequestCards;

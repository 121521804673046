import React, { useState, useEffect } from 'react';

const SaveSessionModal = ({ isOpen, onClose, onConfirm, existingSessions }) => {
    const [sessionName, setSessionName] = useState('');
    const [description, setDescription] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (!isOpen) {
            setSessionName('');
            setDescription('');
            setErrorMessage('');
        }
    }, [isOpen]);

    const handleSave = () => {
        const trimmedSessionName = sessionName.trim();
        if (!trimmedSessionName) {
            setErrorMessage('Session name is required.');
            return;
        }

        if (trimmedSessionName.includes(' ')) {
            setErrorMessage('Session name cannot contain spaces.');
            return;
        }

        if (existingSessions.includes(trimmedSessionName)) {
            if (!window.confirm('This session name already exists. Do you want to overwrite it?')) {
                return;
            }
        }

        onConfirm(trimmedSessionName, description.trim());
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <p>Inserisci un nome univoco della sessione senza spazi</p>
                <form>
                <div class="form-group">
                <label for="sessionname">Session Name</label>
                <input 
                    id="sessionname"
                    type="text" 
                    placeholder="Session Name" 
                    class="form-control"
                    value={sessionName}
                    onChange={(e) => setSessionName(e.target.value)}
                    />
                </div>
                <div class="form-group">
                <label for="session_description">Session Description</label>
                <textarea 
                    id="session_description"
                    placeholder="Session Description"
                    class="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    />
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                    
                <button className="modal-button confirm-button" onClick={handleSave}>Save</button>
                <button className="modal-button confirm-button" onClick={onClose}>Cancel</button>
                </form>
            </div>
        </div>
    );
};

export default SaveSessionModal;

import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, MenuItem, Select, InputLabel, FormControl, Typography, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, IconButton } from '@mui/material';
import { getFirestore, collection, addDoc, getDocs, Timestamp, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';

const AdminMessages = () => {
  const { currentUser, role } = useAuth();
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [recipientId, setRecipientId] = useState('all'); // Default to 'all' for all users
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageToDelete, setMessageToDelete] = useState(null); // Track message to delete
  const db = getFirestore();

  // Fetch users for recipient dropdown
  const fetchUsers = async () => {
    const usersSnapshot = await getDocs(collection(db, 'users'));
    const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(usersList);
  };

  // Fetch messages sent by the admin
  const fetchMessages = async () => {
    const messagesSnapshot = await getDocs(collection(db, 'messages'));
    const messagesList = messagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    // Add recipients' emails to each message
    for (let message of messagesList) {
      if (message.recipientIds.length === 0) {
        message.recipients = 'All Users';
      } else {
        const recipientEmails = [];
        for (let recipientId of message.recipientIds) {
          const userDoc = await getDoc(doc(db, 'users', recipientId));
          if (userDoc.exists()) {
            recipientEmails.push(userDoc.data().email);
          }
        }
        message.recipients = recipientEmails.join(', ');
      }
    }
    
    setMessages(messagesList);
  };

  useEffect(() => {
    fetchUsers();
    fetchMessages();
  }, []);

  const handleSendMessage = async () => {
    try {
      const messageData = {
        subject,
        body,
        senderId: currentUser.uid,
        recipientIds: recipientId === 'all' ? [] : [recipientId], // Empty array means all users
        readBy: [], // Initialize with an empty array
        timestamp: Timestamp.now(),
      };

      await addDoc(collection(db, 'messages'), messageData);

      setSubject('');
      setBody('');
      setRecipientId('all');
      alert('Message sent successfully!');
      fetchMessages(); // Refresh the list of messages
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  const handleDeleteMessage = async () => {
    try {
      await deleteDoc(doc(db, 'messages', messageToDelete.id));
      alert('Message deleted successfully!');
      fetchMessages(); // Refresh the list of messages
      setConfirmDeleteOpen(false); // Close confirmation modal
    } catch (error) {
      console.error('Error deleting message:', error);
      alert('Failed to delete message. Please try again.');
    }
  };

  const handleOpenModal = (message) => {
    setSelectedMessage(message);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedMessage(null);
  };

  const handleOpenDeleteConfirm = (message) => {
    setMessageToDelete(message);
    setConfirmDeleteOpen(true);
  };

  const handleCloseDeleteConfirm = () => {
    setConfirmDeleteOpen(false);
    setMessageToDelete(null);
  };

  if (role !== 'Admin') {
    return <Typography variant="h6">You do not have permission to view this page.</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>Admin Messages</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>Send Message</Typography>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Body"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    multiline
                    rows={4}
                    required
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Recipient</InputLabel>
                  <Select
                    value={recipientId}
                    onChange={(e) => setRecipientId(e.target.value)}
                  >
                    <MenuItem value="all">All Users</MenuItem>
                    {users.map(user => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.email}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendMessage}
                  sx={{ mt: 2 }}
                >
                  Send Message
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>Sent Messages</Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Subject</TableCell>
                        <TableCell>Recipient(s)</TableCell>
                        <TableCell>Date Sent</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {messages.map((message) => (
                        <TableRow key={message.id}>
                          <TableCell>{message.subject}</TableCell>
                          <TableCell>{message.recipients}</TableCell>
                          <TableCell>{new Date(message.timestamp.seconds * 1000).toLocaleString()}</TableCell>
                          <TableCell>
                            <IconButton
                              color="primary"
                              onClick={() => handleOpenModal(message)}
                            >
                              <FontAwesomeIcon icon={faSearch} />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => handleOpenDeleteConfirm(message)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Modal for viewing message details */}
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            {selectedMessage && (
              <>
                <Typography id="modal-title" variant="h6" component="h2">
                  {selectedMessage.subject}
                </Typography>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                  {selectedMessage.body}
                </Typography>
                <Typography variant="caption" display="block" sx={{ mt: 2 }}>
                  Sent at: {new Date(selectedMessage.timestamp.seconds * 1000).toLocaleString()}
                </Typography>
                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                  Recipient(s): {selectedMessage.recipients}
                </Typography>
              </>
            )}
            <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained">Close</Button>
          </Box>
        </Modal>

        {/* Confirmation modal for deleting a message */}
        <Modal
          open={confirmDeleteOpen}
          onClose={handleCloseDeleteConfirm}
          aria-labelledby="confirm-delete-title"
          aria-describedby="confirm-delete-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="confirm-delete-title" variant="h6" component="h2">
              Confirm Deletion
            </Typography>
            <Typography id="confirm-delete-description" sx={{ mt: 2 }}>
              Are you sure you want to delete this message?
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleCloseDeleteConfirm} variant="outlined">Cancel</Button>
              <Button onClick={handleDeleteMessage} variant="contained" color="secondary">Delete</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default AdminMessages;

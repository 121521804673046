import React from 'react';
import Navbar from './components/Navbar';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import HeroSection from './components/HeroSection';
import BenefitsSection from './components/home_components/Benefits';
import InfoSection from './components/home_components/InfoSection';
import StatsSection from './components/home_components/Statistics';
import Footer from './components/Footer';
import Creativita from './assets/img/Creativita.png';
// import LibroSinergie from './assets/img/Libro.png';
import LibroCarte from './assets/img/Mix Herosection.png';
import CardCarousel from './components/home_components/Cards';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Advantages from './components/home_components/Advantages';
import ProductSalesSection from './components/home_components/ProductSales';


function Home() {
  return (    
    <ThemeProvider theme={theme}>
      <Navbar />
      <HeroSection />
      
      <InfoSection 
        imageUrl= {Creativita} // Replace with your image path
        title="Trasforma le Tue Idee in Innovazione con Sinergie Generative"
        description="Immagina un processo di brainstorming che combina la creatività umana con l'intelligenza artificiale. Con Sinergie Generative, non stai solo sviluppando idee, stai co-creando innovazioni che possono rivoluzionare il tuo settore.
        Questo framework unico ti offre strumenti e metodologie progettati per potenziare ogni fase del pensiero creativo. Le nostre AI Cards ti guidano passo dopo passo, ispirandoti con nuove prospettive e aiutandoti a superare i limiti del brainstorming tradizionale.
        Che tu sia un imprenditore, un leader di progetto o un innovatore, Sinergie Generative è l'alleato perfetto per trasformare visioni in realtà concrete. Non si tratta solo di pensare fuori dagli schemi, ma di ridisegnarli completamente.
        Pronto a scoprire il potenziale inesplorato delle tue idee?"
        buttonText="Prova gratis 10 cards"
        buttonurl='/login'
      />
      <BenefitsSection />
      <StatsSection />
      <CardCarousel />
      <InfoSection
        imageUrl= {LibroCarte} // Replace with your image path
        title="Ottieni il Massimo con il Libro e le Cards"
        description="Con l'iscrizione gratuita, puoi iniziare subito a esplorare il potenziale delle nostre AI Cards. Riceverai accesso immediato a 10 carte selezionate per aiutarti a generare idee fresche e innovative. È l'introduzione perfetta al nostro metodo, ma è solo l'inizio.

        Vuoi portare il tuo brainstorming al livello successivo?

        Quando acquisti il nostro libro, non solo ottieni una guida dettagliata alle metodologie più avanzate, ma anche l'accesso alla versione Pro delle AI Cards digitali: 40 carte complete per un'esperienza di brainstorming davvero trasformativa. Con la versione Pro, ogni sessione diventa un viaggio creativo senza limiti, offrendoti strumenti e ispirazioni che ti aiutano a passare dall'idea al prodotto finale con fiducia e precisione.

        Hai già acquistato il libro?

        Inviaci la prova d'acquisto e sblocca subito l'accesso alla versione Pro delle AI Cards. Non perdere l'opportunità di esplorare tutte le possibilità e dare vita alle tue migliori idee."  
        buttonText="Usa subito le Cards"
        buttonurl='/dashboard'
      />
      <Advantages /> 
      <ProductSalesSection /> 
      <Footer />
    </ThemeProvider>
  );
}

export default Home;

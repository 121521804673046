import React from 'react';
import { Box, Typography, Grid, Paper, useTheme } from '@mui/material';
// import CircleIcon from '@mui/icons-material/Circle'; // Placeholder icon, replace with the actual icons you're using
import icon1 from '../../assets/img/1.png';

// Define a card component for each item
const CardItem = ({ title, description }) => {
  return (
    <Grid item xs={12} sm={4}>
      <Paper elevation={3} sx={{ bgcolor: '#30C5FF', p: 2, borderRadius: 2, minHeight: 200 }}>
      <Box component="img" src={icon1} alt="icon" sx={{ height: 'auto', width: 80 }} />
        {/* <AllInclusiveIcon/> */}
        <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold', color: 'common.white' }}>
          {title}
        </Typography>
        <Typography sx={{ color: 'common.white' }}>
          {description}
        </Typography>
      </Paper>
    </Grid>
  );
};


// Main component
const Advantages = () => {
    const theme = useTheme();
  const cardData = [
    {
      title: 'Esplorare l\'Innovazione',
      description: 'Sinergie Generative spalanca le porte alla collaborazione tra menti brillanti e intelligenza artificiale, dando vita a idee che segnano il domani.',
    },
    {
      title: 'Andare oltre l\'Immaginazione',
      description: 'Sinergie Generative ti equipaggia con gli strumenti per trasformare pensieri astratti in prototipi tangibili, illuminando il percorso dalla concezione alla realizzazione.',
    },
    {
      title: 'Essere Etici e Sostenibili ',
      description: 'Sinergie Generative aiuta a creare nuove raffigurazioni della realtà e del futuro etico e sostenibile focalizzando i partecipanti a ragionare sui temi più attuali in ambito GenAI..',
    },
    // Add more cardData if needed
  ];
 

  return (
    <Grid sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'hidden',
        backgroundColor: "#dedede",
        // margin: theme.spacing(4),
        px: theme.spacing(20),
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
          px: theme.spacing(2),
        },
      }}>
    <Box sx={{ flexGrow: 1, py: 10, backgroundColor: theme.palette.grey }}>
        <Typography variant="h4" component="h1" sx={{ color: 'black', textAlign: 'center', mb: 2, py:4 }}>
          Sinergie Generative è l'ideale per..
        </Typography>

      <Grid container spacing={2} justifyContent="center">
        {cardData.map((card, index) => (
          <CardItem key={index} {...card} />
        ))}
      </Grid>
    </Box>
    </Grid>
  );
};

export default Advantages;
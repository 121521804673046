import React, { useState, useRef, useEffect, memo } from 'react';
import { Handle, Position } from 'react-flow-renderer';

const ResizableTextNode = memo(({ id, data, isConnectable, selected }) => {
  const [text, setText] = useState(data.label);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const newHeight = textareaRef.current.scrollHeight + 'px';
      textareaRef.current.style.height = newHeight;
    }
  }, [text]);

  const handleTextUpdate = (e) => {
    const newText = e.target.value;
    setText(newText);
    if (data.onNodeUpdate) {
      console.log("onNodeUpdate is being called.");
      data.onNodeUpdate(id, newText);
    } else {
      console.log("onNodeUpdate is not defined.");
    }
  };

  return (
    <>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      <textarea
        ref={textareaRef}
        value={text}
        onChange={handleTextUpdate}
        style={{
          width: '100%',
          minHeight: '50px',
          border: 'none',
          resize: 'none',
          padding: '10px',
          boxSizing: 'border-box',
          backgroundColor: selected ? 'lightyellow' : 'white',
          outline: selected ? '2px solid blue' : 'none',
        }}
        disabled={!selected}  // Ensure this only disables when truly not selected
      />
      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </>
  );
});

export default ResizableTextNode;
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AnimationIcon from '@mui/icons-material/Animation';
const Statistic = ({ icon, number, label }) => {
    return (
      <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <Box sx={{ color: 'white', fontSize: '3rem' }}>{icon}</Box>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>
          {number}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'white' }}>
          {label}
        </Typography>
      </Grid>
    );
  };
  
  const StatsSection = () => {
    return (
      <Box sx={{  bgcolor: 'primary.main', p: 6 }}>
        <Typography variant="h3" component="h1" sx={{ color: 'white', textAlign: 'center', mb: 2 }}>
          Le Cards: dal pensiero, all'idea, al prodotto
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'white', textAlign: 'center', mb: 4 }}>
          Pensa alle possibili combinazioni che potrai esplorare
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Statistic icon={<AllInclusiveIcon sx={{ fontSize: '3rem' }}  />} number="40" label="Cards" />
          <Statistic icon={<AnimationIcon sx={{ fontSize: '3rem' }}  />} number="5" label="Linee di Pensiero" />
        </Grid>
      </Box>
    );
  };
  
  export default StatsSection;
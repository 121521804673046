import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import RequestCards from './RequestCards';
import Login from './components/Login';
import Signup from './components/Signup';
import { AuthProvider } from './contexts/AuthContext';
import UserDashboard from './private/dashboard';
import PrivateRoute from './contexts/privateroute';
import AdminRoute from './contexts/adminroute';
import AdminDashboard from './admin/admindashboard';
import AdminMessages from './admin/AdminMessages';
import VerifyEmail from './components/verifyemail';
import PrivacyPolicy from './components/privacy-policy';

function App() {
  return (
    <div id="root">
      <div className="main-content">
    <AuthProvider>
    <Routes>
      {/* ... other routes */}
      <Route path="/" element={<Home />} />
      <Route path="/request-cards" element={<PrivateRoute><RequestCards /></PrivateRoute>} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/dashboard" element={<PrivateRoute><UserDashboard /></PrivateRoute>} />
      <Route path="/admindashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
      <Route path="/adminmessages" element={<AdminRoute><AdminMessages /></AdminRoute>} />
    </Routes>
    </AuthProvider>
    </div>
    </div>
  );
}

export default App;
